import styled, { keyframes } from "styled-components";

export const AudioContainer = styled.div`
    background-color: white;
    padding: 10px 7px;
    border-radius: 8px;
    border: 1px solid #ccc;
    /* box-shadow: 0 0px 2px rgba(0, 0, 0, 0.2); */
    width: 100%;
    display: flex;
    align-items: center;
    & > * {
        margin-right: 8px;
    }
`;

export const AudioRecordButton = styled.button`
    border: none;
    outline: none;
    background-color: #9fbc59;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    cursor: pointer;
    font-size: 20px;
`;
export const PlayButton = styled.button`
    border: none;
    outline: none;
    background-color: #9fbc59; /* Default green */
    border-radius: 50%;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    cursor: pointer;
    font-size: 24px; /* Slightly larger font */
    position: relative;
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;

    /* Glow animation for play icon */
    &.play-icon {
        animation: glow 1.5s ease-in-out infinite, pulse 1.5s ease-out infinite;
    }

    /* Background color changes when playing */
    &.playing {
        background-color: #e74c3c; /* Red when playing */
    }

    /* Hover effect */
    // &:hover {
    //     transform: scale(1.1); /* Slight scale on hover */
    //     box-shadow: 0 0 20px rgba(255, 99, 71, 0.8); /* Soft glowing shadow */
    // }

    /* Active effect when clicked */
    &:active {
        transform: scale(1); /* No scaling when active */
        box-shadow: 0 0 10px rgba(255, 99, 71, 1); /* Stronger shadow when clicked */
    }

    /* Keyframe for glowing effect */
    @keyframes glow {
        0% {
            box-shadow: 0 0 10px rgba(255, 99, 71, 0.5), 0 0 20px rgba(255, 99, 71, 0.5);
        }
        50% {
            box-shadow: 0 0 20px rgba(255, 99, 71, 0.8), 0 0 30px rgba(255, 99, 71, 0.8);
        }
        100% {
            box-shadow: 0 0 10px rgba(255, 99, 71, 0.5), 0 0 20px rgba(255, 99, 71, 0.5);
        }
    }

    /* Pulsing effect for the play icon */
    @keyframes pulse {
        0% {
            transform: scale(1);
        }
        50% {
            transform: scale(1.1);
        }
        100% {
            transform: scale(1);
        }
    }
`;

const Pulse = keyframes`
  0% { transform: scale(0); opacity:1 }
  100% { transform: scale(1.2); opacity:0}
`;
export const AudioRecordingButton = styled(AudioRecordButton)`
    position: relative;
    color: #ccc;
    background-color: transparent;
    &::before,
    &::after {
        content: "";
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        border-radius: 50%;
        bottom: 0;
        z-index: 0;
        background-color: #9fbc59;
        animation: ${Pulse} 2s linear infinite;
        opacity: 0;
    }
    &::after {
        animation: ${Pulse} 2s linear 2.3s infinite;
    }
`;

export const AudioDiscardButton = styled(AudioRecordButton)`
    background-color: transparent;
    color: #333;
`;
