import React, { createContext, useContext, useEffect, useRef } from "react";

const InactivityContext = createContext();

export const InactivityProvider = ({ children, inactivityLimit = 30 * 60 * 1000 }) => {
  const inactivityTimeoutRef = useRef(null);

  const handleLogout = () => {
    // console.log("User inactive, logging out...");
    localStorage.removeItem("userNumber");
    localStorage.removeItem("lastWebAppActivity");
    window.open("/", "_self"); // Redirect to login page
  };

  const resetInactivityTimer = () => {
    const now = Date.now();
    localStorage.setItem("lastWebAppActivity", now);

    if (inactivityTimeoutRef.current) {
      clearTimeout(inactivityTimeoutRef.current);
    }

    inactivityTimeoutRef.current = setTimeout(handleLogout, inactivityLimit);
  };

  const checkInactivity = () => {
    const lastActivity = localStorage.getItem("lastWebAppActivity");
    const now = Date.now();

    // Check if inactivity limit has been exceeded based on last activity time
    if (lastActivity && now - lastActivity > inactivityLimit) {
      handleLogout(); // Log out if the inactivity limit is exceeded
    } else {
      resetInactivityTimer(); // Otherwise, start/reset the inactivity timer
    }
  };

  useEffect(() => {
    // Check inactivity status when the tab is loaded or page is refreshed
    checkInactivity();

    // Add event listeners to reset inactivity timer on user actions
    const events = ["mousemove", "keydown", "click", "touchstart", "touchmove"];
    events.forEach((event) => document.addEventListener(event, resetInactivityTimer));

    // Handle visibility change (when the user switches tabs or minimizes the browser)
    const handleVisibilityChange = () => {
      if (document.hidden) {
        // When tab is hidden, pause the inactivity timer
        if (inactivityTimeoutRef.current) {
          clearTimeout(inactivityTimeoutRef.current);
        }
      } else {
        // When tab becomes visible again, check for inactivity
        checkInactivity();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    // Cleanup function to remove event listeners when component unmounts
    return () => {
      if (inactivityTimeoutRef.current) {
        clearTimeout(inactivityTimeoutRef.current);
      }
      events.forEach((event) => document.removeEventListener(event, resetInactivityTimer));
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [inactivityLimit]);

  return (
    <InactivityContext.Provider value={{ resetInactivityTimer }}>
      {children}
    </InactivityContext.Provider>
  );
};

// Custom hook for using inactivity context
export const useInactivity = () => useContext(InactivityContext);
