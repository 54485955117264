// ReferralLanding.tsx
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import axios from "axios";
import api from "../../api";

const ReferralLanding = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const handleReferral = async () => {
      if (isLoading) return; // Prevent making multiple calls if already in progress
      setIsLoading(true);
      try {
        const urlParams = new URLSearchParams(window.location.search);
        const refCode = urlParams.get("referrer");
        if (!refCode) {
          navigate("/");
          return;
        }

        // Get referrer URL to determine where the link was clicked
        const referrer = document.referrer;
        // console.log("Referrer URL:", referrer); // For debugging

        // Determine mode based on referrer URL
        let mode = "direct";

        if (referrer) {
          // WhatsApp web
          if (referrer.includes("whatsapp.com") || referrer.includes("wa.me")) {
            mode = "whatsapp";
          }
          // Facebook
          else if (
            referrer.includes("facebook.com") ||
            referrer.includes("fb.com")
          ) {
            mode = "facebook";
          }
          // Telegram
          else if (
            referrer.includes("t.me") ||
            referrer.includes("telegram.me")
          ) {
            mode = "telegram";
          }
          // Twitter
          else if (
            referrer.includes("twitter.com") ||
            referrer.includes("t.co")
          ) {
            mode = "twitter";
          }
          // LinkedIn
          else if (referrer.includes("linkedin.com")) {
            mode = "linkedin";
          }
          // Email clients (limited support)
          else if (
            referrer.includes("mail.") ||
            referrer.includes("outlook.") ||
            referrer.includes("gmail.")
          ) {
            mode = "email";
          }
        }

        // Call backend with the detected mode
        const response = await api.get(`/referral/ref-click`, {
          params: {
            ref: refCode,
            mode: mode,
          },
          headers: { "Cache-Control": "no-cache" },
        });
        if (response.status == 200) {
          localStorage.setItem("referralClickId", response.data.id);
        }
        setIsLoading(false);
        // console.log("Referrer URL:", response);
        navigate("/registration");
      } catch (error) {
        console.error("Error processing referral:", error);
        navigate("/");
        setIsLoading(false);
      }
    };

    handleReferral();
  }, [navigate, isLoading]);

  return (
    <div className="referral-landing">
      <p>Please wait while we redirect you...</p>
    </div>
  );
};

export default ReferralLanding;
