import Recorder from "recorder-js";
import React, { Component } from "react";
import api from "../api";
import FullPageLoader from "../elements/FullpageLoader";
import { showNotification } from "../actions/index.actions";
import {
    AudioContainer,
    AudioRecordButton,
    AudioDiscardButton,
    AudioRecordingButton,
    PlayButton,
} from "./audio.element";
/**
 * @author Suman Kumar (26 July 2024)
 * @description This component is used to record audio using RecorderJs and upload it to the server.
 */
class AudioRecorderJs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            blob: null,
            isRecording: false,
            stream: null,
            analyserData: { data: [], lineTo: 0 },
            isPlaying: false,
            isUploading: false,
            audioBlobUrl: null,
            fileUrl: null,
            deletedFileUrl: null,
            name: props.name,
            savingResponse: false,
            latitude: this.props.latitude,
            longitude: this.props.longitude,
        };

        this.audioPlayerRef = React.createRef(null);

        if (props.name == "audioSurvey") {
            this.audioContext = new (window.AudioContext ||
                window.webkitAudioContext)({
                    sampleRate: 8000,
                    sampleSize: 8,
                });
        } else {
            this.audioContext = new (window.AudioContext ||
                window.webkitAudioContext)();
        }

        this.recorder = null;

        this.start = this.start.bind(this);
        this.stop = this.stop.bind(this);
    }

    getSnapshotBeforeUpdate(prevProps) {
        return prevProps.deleteAudio !== this.props.deleteAudio;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.isChangeClick != this.props.isChangeClick) {
            this.discardClick();
        }
        if (snapshot) {
            const deletedFileUrl = this.state.fileUrl;
            this.setState({
                ...this.state,
                isPlaying: false,
                audioBlobUrl: null,
                fileUrl: null,
                blob: null,
                analyserData: { data: [], lineTo: 0 },
                deletedFileUrl,
            });
            this.onChange();
        }
    }

    start = () => {
        this.audioPlayerRef.current = null;
        this.recorder = new Recorder(this.audioContext, {
            onAnalysed: (data) => this.setState({ analyserData: data }),
        });

        navigator.mediaDevices
            .getUserMedia({ audio: true })
            .then((stream) => {
                this.setState({ stream });
                this.recorder.init(stream);
                this.recorder
                    .start()
                    .then(() => this.setState({ isRecording: true, audioBlobUrl: null }));
            })
            .catch(this.dontGotStream);
    };

    stop = () => {
        this.recorder.stop().then(({ blob }) => {
            const audioUrl = URL.createObjectURL(blob);
            this.setState({
                isRecording: false,
                audioBlobUrl: audioUrl,
                blob,
            });
            this.state.stream.getAudioTracks()[0].stop();
            this.onChange();
        });
    };

    dontGotStream = (error) => {
        showNotification("Audio Recorder", "Get stream failed", "danger");
    };

    play = () => {
        if (!this.audioPlayerRef || !this.audioPlayerRef.current) {
            this.audioPlayerRef.current = new Audio(this.state.audioBlobUrl);
            this.setState({ ...this.discardstate, isPlaying: true });
            this.audioPlayerRef.current.addEventListener("ended", () => {
                this.setState({ ...this.state, isPlaying: false });
            });
            this.audioPlayerRef.current.play();
            return;
        }
        if (this.audioPlayerRef.current.paused) {
            this.setState({ ...this.state, isPlaying: true });
            this.audioPlayerRef.current.play();
        } else {
            this.setState({ ...this.state, isPlaying: false });
            this.audioPlayerRef.current.pause();
        }
    };

    upload = async () => {
        if (!this.props.latitude || !this.props.longitude) {
            return showNotification(
                "Error",
                "Location is required",
                "danger"
            );
        }
        const userLocationPermission = localStorage.getItem("locationGranted");
        if (userLocationPermission === "true") {
            this.props.requestLocation();
        }
        this.setState({ ...this.state, isUploading: true });
        const fileName = `${Date.now()}.wav`;
        const file = new File([this.state.blob], fileName, {
            type: `audio/wav`,
            lastModified: new Date().getTime(),
        });
        const formData = new FormData();
        // formData.append("tag", "kn-audiosurvey");
        formData.append("tag", "dtd-prod-audio");
        formData.append("file", file);
        try {
            const response = await api.post(
                "/api/protected/file/upload/audio",
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );
            // console.log("response", response);
            showNotification("Success", "Audio has been uploaded", "success");
            this.setState(
                {
                    ...this.state,
                    isUploading: false,
                    fileUrl: response.data.fileName[0],
                    latitude: this.props.latitude,
                    longitude: this.props.longitude
                },
                () => {
                    this.onChange();
                    this.saveResponse();
                }
            );
        } catch (err) {
            this.setState({ ...this.state, isUploading: false });
            showNotification("Error", "Audio upload failed", "danger");
            console.log(err);
        }
    };

    saveResponse = () => {
        const { questionData } = this.props;
        const { latitude, longitude } = this.state;
        // console.log('state', this.state.latitude, this.state.longitude)
        // console.log('props', this.props.latitude, this.props.longitude)
        const { fileUrl } = this.state;
        this.setState({ savingResponse: true });
        this.props
            .updateSurveyQuestionData({
                userId: questionData.userId,
                voiceTaskId: +questionData.VoiceTasksID,
                voiceTaskLangID: questionData.LangID || 0,
                taskUrl: fileUrl,
                latitude: latitude,
                longitude: longitude
            })
            .then(() => {
                this.setState({ savingResponse: false });
                showNotification("Success", "Response has been saved", "success");
                this.discardClick();
            })
            .catch((err) => {
                console.log(err);
                this.setState({ savingResponse: false });
                showNotification(
                    "Error",
                    "Something went wrong, please retry",
                    "danger"
                );
            });
    };

    discard = () => {
        const confirm = window.confirm(
            "Are you sure you want to discard the recording?"
        );
        if (!confirm) return;
        const deletedFileUrl = this.state.fileUrl;
        this.setState({
            ...this.state,
            isPlaying: false,
            isRecording:false,
            audioBlobUrl: null,
            fileUrl: null,
            blob: null,
            deletedFileUrl,
        });
        if (
            this.audioPlayerRef &&
            this.audioPlayerRef.current &&
            this.audioPlayerRef.current.paused === false
        ) {
            this.audioPlayerRef.current.pause();
        }
        this.audioPlayerRef.current = null;
        setTimeout(() => {
            this.onChange();
        }, 100);
    };
    discardClick = () => {
        const deletedFileUrl = this.state.fileUrl;
        this.setState({
            ...this.state,
            isPlaying: false,
            isRecording:false,
            audioBlobUrl: null,
            fileUrl: null,
            blob: null,
            deletedFileUrl,
        });
        if (
            this.audioPlayerRef &&
            this.audioPlayerRef.current &&
            this.audioPlayerRef.current.paused === false
        ) {
            this.audioPlayerRef.current.pause();
        }
        this.audioPlayerRef.current = null;
        setTimeout(() => {
            this.onChange();
        }, 100);
    };

    onChange = () => {
        this.props.onChange(this.state);
    };

    render() {
        const { fileUrl, isPlaying, isUploading, audioBlobUrl, isRecording } =
            this.state;
        return (
            <div style={{ width: "100%" }}>
                <AudioContainer>
                    {isRecording === false && audioBlobUrl === null ? (
                        <AudioRecordButton
                            disabled={isUploading}
                            tooltip="test"
                            onClick={this.start}
                        >
                            <i className="fa fa-microphone"></i>
                        </AudioRecordButton>
                    ) : null}
                    {isRecording === true ? (
                        <React.Fragment>
                            <AudioRecordingButton disabled={isUploading} tooltip="test">
                                <i className="fa fa-microphone"></i>
                            </AudioRecordingButton>
                            <AudioDiscardButton disabled={isUploading} onClick={this.stop}>
                                <i className="fa fa-stop-circle"></i>
                            </AudioDiscardButton>
                        </React.Fragment>
                    ) : null}

                    {isRecording === false && audioBlobUrl ? (
                        <React.Fragment>
                            <PlayButton
                                disabled={isUploading}
                                onClick={this.play}
                                className="play-icon playing"
                            >
                                {isPlaying ? (
                                    <i className="fa fa-pause"></i>
                                ) : (
                                    <i className="fa fa-play"></i>
                                )}
                            </PlayButton>
                            <AudioDiscardButton disabled={isUploading} onClick={this.discard}>
                                <i className="fa fa-trash"></i>
                            </AudioDiscardButton>
                        </React.Fragment>
                    ) : null}
                    {audioBlobUrl ? (
                        <div style={{ marginLeft: "auto" }}>
                            {fileUrl ? (
                                <p></p>
                            ) : (
                                <button
                                    disabled={isUploading}
                                    onClick={this.upload}
                                    style={{ margin: "0" }}
                                    className="btn btn-sm btn-default"
                                >
                                    {isUploading === false ? (
                                        <React.Fragment>
                                            Save <i className="fa fa-upload" />
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            Uploading...{" "}
                                            <i
                                                style={{ fontSize: "14px", color: "black" }}
                                                className="fa fa-spin fa-spinner"
                                            />
                                        </React.Fragment>
                                    )}
                                </button>
                            )}
                        </div>
                    ) : null}
                </AudioContainer>
                {isRecording ? (
                    <span
                        style={{ fontSize: "14px", paddingLeft: "8px", color: "#2b0a41" }}
                    >
                        Recording...
                    </span>
                ) : null}
                {this.state.savingResponse || this.state.isUploading ? (
                    <FullPageLoader message="Saving response... please wait" />
                ) : null}
            </div>
        );
    }
}
export default AudioRecorderJs;
