import React, { useState, useEffect } from "react";
import Select from "react-select";
import { getURL } from "../../commons/commons";
import PropTypes from "prop-types";
import api from '../../api';
/**
 * @author Suman Kumar. 26 August 2024
 */
function AllLanguageList(props) {
  const [isLoading, setLoader] = useState(true);
  const [options, setOptions] = useState([]);
  useEffect(() => {
    // Define an async function inside the useEffect
    const fetchCountrylists = async () => {
      try {
        const url = getURL(`/api/fetch/language-list/all?countryCode=${props.countryCode}`);
        const response = await api.get(url, {
          headers: { "Cache-Control": "no-cache" },
        });
        const languageList = response.data.languageList;
        // const sortedData = languageList.sort((a, b) =>
        //     a.language.localeCompare(b.language)
        // );
        setOptions(languageList);
      } catch (error) {
        console.error("Error fetching language lists:", error);
      } finally {
        setLoader(false);
      }
    };

    fetchCountrylists();
  }, [props.countryCode]);
  return (
    <Select
      options={options}
      isLoading={isLoading}
      onChange={props.onChange}
      value={props.value}
      isClearable={false}
      placeholder="Select language"
      getOptionLabel={(option) => option.language} // Use the custom label format
      getOptionValue={(option) => option.id} // Use the custom value format
      {...props.options}
    />
  );
}

AllLanguageList.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  options: PropTypes.object, // Prop types for additional options if needed
};

export default AllLanguageList;
