import React, { useState } from "react";
import Modal from "react-modal";
import { showNotification } from "../../actions/index.actions";

// Modal styles
const modalStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    position: "relative",
    top: "auto",
    left: "auto",
    right: "auto",
    bottom: "auto",
    border: "1px solid #ccc",
    background: "#fff",
    borderRadius: "8px",
    padding: "20px",
    maxWidth: "500px",
    width: "90%",
    maxHeight: "80vh",
  },
};

const styles = {
  modalContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },
  title: {
    margin: 0,
    fontSize: "24px",
    color: "#333",
    textAlign: "center",
  },
  messageContainer: {
    backgroundColor: "#f5f5f5",
    padding: "15px",
    borderRadius: "8px",
  },
  textarea: {
    width: "100%",
    minHeight: "100px",
    padding: "10px",
    border: "none",
    backgroundColor: "transparent",
    resize: "none",
    fontSize: "14px",
    lineHeight: "1.5",
    outline: "none",
  },
  buttonContainer: {
    display: "flex",
    gap: "10px",
    justifyContent: "flex-end",
  },
  copyButton: {
    padding: "10px 20px",
    backgroundColor: "#007bff",
    color: "white",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    fontSize: "14px",
    fontWeight: "bold",
  },
  successButton: {
    backgroundColor: "#28a745",
  },
  closeButton: {
    padding: "10px 20px",
    backgroundColor: "#6c757d",
    color: "white",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    fontSize: "14px",
  },
};

const ShareModal = ({ isOpen, onClose, message }) => {
  const [copySuccess, setCopySuccess] = useState(false);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(message);
      setCopySuccess(true);
      showNotification(
        "Success",
        "Full invitation message copied to clipboard!",
        "success"
      );
      setTimeout(() => {
        setCopySuccess(false);
        onClose();
      }, 1500);
    } catch (err) {
      console.error("Copy failed:", err);
      showNotification(
        "Error",
        "Failed to copy to clipboard. Please try again.",
        "danger"
      );
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={modalStyles}
      contentLabel="Share Modal"
      ariaHideApp={false} // Temporarily disable the warning while we set up proper app element
    >
      <div style={styles.modalContainer}>
        <h2 style={styles.title}>Share Invitation</h2>
        <div style={styles.messageContainer}>
          <textarea value={message} readOnly style={styles.textarea} />
        </div>
        <div style={styles.buttonContainer}>
          <button
            onClick={handleCopy}
            style={{
              ...styles.copyButton,
              ...(copySuccess ? styles.successButton : {}),
            }}
          >
            {copySuccess ? "Copied!" : "Copy to Clipboard"}
          </button>
          <button onClick={onClose} style={styles.closeButton}>
            Close
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ShareModal;
