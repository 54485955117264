import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import {
    getSurveyQuestionData,
    updateSurveyQuestionData,
} from "../actions/Survey/index";
import LoadingUI from "../elements/LoadingUI";
import NoDataFound from "../elements/NoDataFound";
import QuestionListUI from "./QuestionListUI";
import { showNotification } from "../actions/index.actions";

const AudioWorkFlow = (props) => {
    const userNumber = localStorage.getItem("userNumber");
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [formData, setFormData] = useState({ number: userNumber });
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);
   // console.log("Audio-task", latitude, longitude);
    const [locationGranted, setLocationGranted] = useState(false);
    const [locationBlocked, setLocationBlocked] = useState(false);
    const [showLocationModal, setShowLocationModal] = useState(false);
    const [isLocationLoading, setLocationLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('No active voice task types found for the specified language.');
    // useRef to track if the data has been fetched already
    const hasFetchedData = useRef(false);
    useEffect(() => {
        if (!userNumber) {
            window.open("/", "_self")
        }
        if (userNumber && !hasFetchedData.current) {
            // Mark that the data has been fetched
            hasFetchedData.current = true;
            // Call the API to fetch survey question data
            getSurveyQuestionData({ number: userNumber });
        }
        // Handle location permission and fetching logic
        const userLocationPermission = localStorage.getItem("locationGranted");
        if (userLocationPermission === "true") {
            setLocationGranted(true);
            setShowLocationModal(false);
            requestLocation();
        } else {
            setLocationGranted(false);
            setShowLocationModal(true);
        }
        setLocationLoading(false);
    }, [userNumber]); // Dependencies: will only re-run if userNumber changes

    // Request location access
    const requestLocation = () => {
        setLocationLoading(true);
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    setLatitude(latitude);
                    setLongitude(longitude);
                    setLocationGranted(true);
                    setLocationBlocked(false);
                    setShowLocationModal(false);
                    localStorage.setItem("locationGranted", "true");
                    localStorage.setItem("locationBlocked", "false");
                },
                (error) => {
                    if (error.code === error.PERMISSION_DENIED) {
                        // User has denied the location request
                        setLocationGranted(false);
                        setLocationBlocked(true);
                        setShowLocationModal(true); // Show modal with instructions
                        localStorage.setItem("locationBlocked", "true");
                        localStorage.setItem("locationGranted", "false");
                        showNotification("Error", "Location access denied. Please enable it in your browser settings.", "danger");
                    } else {
                        // Handle other errors (e.g., timeout or unavailable geolocation)
                        showNotification("Error", "Unable to access location.", "danger");
                    }
                    setLocationLoading(false); // End loading
                }
            );
        } else {
            showNotification(
                "Error",
                "Geolocation is not supported by this browser.",
                "danger"
            );
        }
    };
    // Render location modal if needed
    const renderLocationModal = () => {
        if (isLocationLoading) {
            return null; // Don't render anything during the loading phase
        }
        return (
            <div style={styles.modal}>
                <div style={styles.modalContent}>
                    {locationBlocked ? null : <React.Fragment>
                        <h3>We need your location!</h3>
                        <p>
                            To provide better services, we require access to your location.
                            Please click "Allow Location" to grant us permission.
                        </p>
                        <p style={{ color: "#1d5dc9" }}>Note: Please do not click on "Never" when location popup/dialog appears</p>
                        <button onClick={requestLocation} style={styles.modalButton} disabled={isLocationLoading}>
                            {isLocationLoading ? "Please Wait..." : "Allow Location"}
                        </button>
                    </React.Fragment>}
                    {locationBlocked && (
                        <div style={{
                            textAlign: "left",
                            color: '#ba1d1d',
                            background: '#fff8f8',
                            padding: '2px 2px',
                            borderRadius: '5px',
                        }}>
                            <p style={styles.blockedText}>
                                Location access is required to continue. Please enable it in your browser settings:
                                <br />
                                1. Go to your browser settings.
                                <br />
                                2. Find "Site Settings" or "Permissions".
                                <br />
                                3. Find "Location" within "Permissions" and check the "Blocked" section.
                                <br />
                                4. You will find this site in your blocked section "https://taskers.thedatataskers.com".
                                <br />
                                5. Click and remove this, and enable location access for this site.
                            </p>
                        </div>
                    )}
                </div>
            </div>
        );
    };

    const getSurveyQuestionData = (reqObj) => {
        setIsLoading(true);
        setIsError(false);
        props.getSurveyQuestionData(reqObj)
            .then(() => {
                setIsLoading(false);
                setIsError(false);
            })
            .catch((error) => {
                setIsLoading(false);
                setIsError(true);
                const errorMsg = error.response?.data?.message || "No active voice task types found for the specified language.";
               // console.log(errorMsg)
                setErrorMessage(errorMessage)
                showNotification("Error", errorMsg, "danger");
            });
    };

    const getUI = () => {
        const { surveyQuestionDataList } = props;
        const filteredQuestions =
            surveyQuestionDataList &&
            surveyQuestionDataList.length &&
            surveyQuestionDataList.filter((question) => question.taskUrl == null);
        const attemptedQuestions =
            surveyQuestionDataList &&
            surveyQuestionDataList.length &&
            surveyQuestionDataList.filter((question) => question.taskUrl != null);

        if (isLoading) {
            return (
                <div style={{ textAlign: "center" }}>
                    <LoadingUI message={"Loading question lists"} />
                </div>
            )
        }

        if (isError) {
            return (
                <div style={{ textAlign: "center" }}>
                    <NoDataFound message={errorMessage} />
                </div>
            )


        }

        if (filteredQuestions.length === 0) {
            return (
                <div style={{ textAlign: "center" }}>
                    <NoDataFound message={"No question is available, try again later"} />
                </div>
            );
        }

        return (
            <QuestionListUI
                surveyQuestionDataList={filteredQuestions}
                attemptedQuestions={attemptedQuestions}
                assignedQuestionList={surveyQuestionDataList}
                key="surveyQuestionDataList"
                latitude={latitude}
                longitude={longitude}
                updateSurveyQuestionData={props.updateSurveyQuestionData}
                requestLocation={requestLocation}
            />
        );
    };

    return (
        <div style={styles.container}>
            {showLocationModal && renderLocationModal()}
            {showLocationModal ? null : getUI()}
        </div>
    );
};

const styles = {
    container: {
        // display: "flex",
        // flexDirection: "column",
        // alignItems: "center",
        // minHeight: "100vh",
        // // boxSizing: "border-box",
        // background: "white",
    },
    modal: {
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "#000000e0",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 9999,
    },
    modalContent: {
        backgroundColor: "#fff",
        padding: "20px",
        borderRadius: "10px",
        textAlign: "center",
        width: "80%",
        maxWidth: "400px",
    },
    modalButton: {
        backgroundColor: "#4CAF50",
        color: "white",
        padding: "10px 20px",
        border: "none",
        borderRadius: "5px",
        cursor: "pointer",
        fontSize: "16px",
    },
};

function mapStateToProps({ surveyQuestionDataList }) {
    return { surveyQuestionDataList };
}

export default connect(mapStateToProps, {
    getSurveyQuestionData,
    updateSurveyQuestionData,
    showNotification,
})(AudioWorkFlow);
