import React, { useState, useRef } from "react";
import Modal from "react-modal";
import { showNotification } from "../../actions/index.actions";
import "./FileUploadCss.css";

const modalStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1000,
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  content: {
    position: "relative",
    top: "auto",
    left: "auto",
    right: "auto",
    bottom: "auto",
    border: "1px solid #ccc",
    background: "#fff",
    borderRadius: "8px",
    padding: "20px",
    maxWidth: "500px",
    width: "90%",
    maxHeight: "80vh",
    margin: "20px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    outline: "none",
  },
};

const modalContentStyle = {
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "24px",
  },
  title: {
    fontSize: "20px",
    textAlign: "center",
    fontWeight: "600",
    color: "#1f2937",
    margin: "0 0 20px 0",
  },
  buttonContainer: {
    display: "flex",
    gap: "12px",
    justifyContent: "center",
  },
  button: {
    padding: "12px 24px",
    backgroundColor: "#2563eb",
    color: "white",
    border: "none",
    borderRadius: "6px",
    fontSize: "16px",
    fontWeight: "500",
    cursor: "pointer",
    transition: "background-color 0.2s ease",
    outline: "none",
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  cancelButton: {
    backgroundColor: "#6b7280",
  },
};

const FileUpload = ({
  label,
  onChange,
  onRemove,
  error,
  accept = "image/*",
  required = false,
  disabled = false,
}) => {
  const [preview, setPreview] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const fileInputRef = useRef(null);
  const cameraInputRef = useRef(null);

  const handleFileChange = async (e) => {
    if (disabled) return;
    const file = e.target.files?.[0];
    if (!file) return;

    setIsLoading(true);
    try {
      if (!file.type.match(/^image\/(jpeg|jpg|png)$/i)) {
        throw new Error("Please upload a valid image file (JPEG or PNG)");
      }

      if (file.size > 10 * 1024 * 1024) {
        throw new Error("File size should be less than 10MB");
      }

      const objectUrl = URL.createObjectURL(file);
      setPreview(objectUrl);
      onChange(file);
      showNotification("Success", "File uploaded successfully", "success");
    } catch (err) {
      showNotification(
        "Upload Error",
        err.message || "Error processing file",
        "danger"
      );
      if (fileInputRef.current) fileInputRef.current.value = "";
      if (cameraInputRef.current) cameraInputRef.current.value = "";
    } finally {
      setIsLoading(false);
      setIsModalOpen(false);
    }
  };

  const handleRemove = (e) => {
    e.preventDefault();
    if (disabled) return;

    if (preview) {
      URL.revokeObjectURL(preview);
    }
    setPreview(null);
    onRemove();
    if (fileInputRef.current) fileInputRef.current.value = "";
    if (cameraInputRef.current) cameraInputRef.current.value = "";
    showNotification("Success", "File removed successfully", "success");
  };

  const handleOptionSelect = (useCamera) => {
    if (useCamera) {
      cameraInputRef.current?.click();
    } else {
      fileInputRef.current?.click();
    }
  };

  return (
    <div className={`file-upload ${disabled ? "disabled" : ""}`}>
      <label className="file-label">
        {label} {required && <span className="required">*</span>}
      </label>

      {!preview ? (
        <div
          className={`upload-area ${disabled ? "disabled" : ""}`}
          onClick={() => !disabled && setIsModalOpen(true)}
          role="button"
          tabIndex={disabled ? -1 : 0}
          onKeyPress={(e) =>
            !disabled && e.key === "Enter" && setIsModalOpen(true)
          }
        >
          <input
            ref={fileInputRef}
            type="file"
            accept={accept}
            onChange={handleFileChange}
            disabled={disabled || isLoading}
            style={{ display: "none" }}
          />

          <input
            ref={cameraInputRef}
            type="file"
            accept="image/*"
            capture="environment"
            onChange={handleFileChange}
            disabled={disabled || isLoading}
            style={{ display: "none" }}
          />

          <div className={`upload-placeholder ${disabled ? "disabled" : ""}`}>
            <span>
              {isLoading
                ? "Processing..."
                : disabled
                ? "Upload disabled"
                : "Click to upload photo"}
            </span>
            <small>Upload from camera or gallery (JPG, PNG up to 10MB)</small>
          </div>
        </div>
      ) : (
        <div className={`preview-container ${disabled ? "disabled" : ""}`}>
          <img src={preview} alt="Preview" className="image-preview" />
          {!disabled && (
            <button
              type="button"
              onClick={handleRemove}
              className="remove-button"
              disabled={disabled}
            >
              ×
            </button>
          )}
        </div>
      )}

      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        style={modalStyles}
        contentLabel="Upload Option Modal"
        ariaHideApp={false}
      >
        <div style={modalContentStyle.container}>
          <h2 style={modalContentStyle.title}>Choose Upload Option</h2>
          <div style={modalContentStyle.buttonContainer}>
            <button
              onClick={() => handleOptionSelect(true)}
              style={modalContentStyle.button}
              onMouseOver={(e) => (e.target.style.backgroundColor = "#1d4ed8")}
              onMouseOut={(e) => (e.target.style.backgroundColor = "#2563eb")}
            >
              Take Photo
            </button>
            <button
              onClick={() => handleOptionSelect(false)}
              style={modalContentStyle.button}
              onMouseOver={(e) => (e.target.style.backgroundColor = "#1d4ed8")}
              onMouseOut={(e) => (e.target.style.backgroundColor = "#2563eb")}
            >
              Upload from Gallery
            </button>
          </div>
          <div style={modalContentStyle.buttonContainer}>
            <button
              onClick={() => setIsModalOpen(false)}
              style={{
                ...modalContentStyle.button,
                ...modalContentStyle.cancelButton,
              }}
              onMouseOver={(e) => (e.target.style.backgroundColor = "#4b5563")}
              onMouseOut={(e) => (e.target.style.backgroundColor = "#6b7280")}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>

      {error && <div className="error-text">{error}</div>}
    </div>
  );
};

export default FileUpload;
