import React, { Component } from "react";
import FullpageLoader from "../elements/FullpageLoader";
import { showNotification } from "../actions/index.actions";
import Logo from "../Image/logo2.PNG";
import api from "../api";
import {
    LoginInputContainer,
    NextBtn,
    ResendOtpBtn,
    LogoName,
    RegisterBtn,
} from "../Style";

class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            isError: false,
            isNumberUI: true,
            formData: { number: "", type: "otp" },
            otpSent: false,
            otpTimer: 120,
            timerActive: false,
            isLocationLoading: false,
            locationGranted: false,
            locationBlocked: false, // Flag to check if location access was blocked
            latitude: null,
            longitude: null,
            showLocationModal: false, // Initially, location modal is hidden
        };
    }

    // Request location access when the user clicks "Get OTP"
    requestLocation = () => {
        this.setState({
            isLocationLoading: true,
        });
        // Only show modal when location permission is denied, not before
        if (!this.state.locationGranted && !this.state.locationBlocked) {
            this.setState({ showLocationModal: true });
        }
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    // Update state with latitude, longitude and location granted
                    this.setState(
                        {
                            latitude: latitude,
                            longitude: longitude,
                            locationGranted: true,
                            locationBlocked: false,
                            showLocationModal: false,
                            isLocationLoading: false,
                        },
                        () => {
                            // This callback will be called after the state has been updated
                            localStorage.setItem("locationGranted", "true");
                            localStorage.setItem("locationBlocked", "false");
                            // Call the API now that the state has been updated
                            this.callApi(); // Now call API with the updated state
                        }
                    );
                },
                (error) => {
                    if (error.code === error.PERMISSION_DENIED) {
                        // If permission is denied, update the state and store the "blocked" status
                        this.setState({
                            locationGranted: false,
                            locationBlocked: true, // Mark the location as blocked
                            showLocationModal: true,
                            isLocationLoading: false,
                        });

                        // Store in localStorage
                        localStorage.setItem("locationBlocked", "true");
                        localStorage.setItem("locationGranted", "false");
                    } else {
                        // Handle other types of errors (e.g., timeout, unavailable geolocation)
                        showNotification("Error", "Unable to access location.", "danger");
                        this.setState({
                            isLocationLoading: false,
                        });
                    }
                }
            );
        } else {
            showNotification(
                "Error",
                "Geolocation is not supported by this browser.",
                "danger"
            );
            this.setState({
                isLocationLoading: false,
            });
        }
    };

    // Function to initiate the OTP API call after location is determined
    callApi = async (e) => {
        const { number, type, locationGranted, latitude, longitude } =
            this.state.formData;
        // Validate number and other necessary fields before making the API call
        this.setState({ isLoading: true, isError: false });
        try {
            // Verify if the number exists
            const response = await api.get(`/api/validate-user?number=${number}`, {
                headers: { "Cache-Control": "no-cache" },
            });

            if (response.status === 200) {
                // If the number exists, send the OTP
                await api.post("/api/send-otp", { number, type });
                this.setState({ otpSent: true, timerActive: true, isLoading: false });
                showNotification("Success", "OTP sent successfully!", "success");
                this.startTimer();
            }
        } catch (error) {
            let errorMsg =
                error.response?.data?.message || "No user found with this number";
            showNotification("Error", errorMsg, "danger");
            this.setState({ isLoading: false, isError: true });
        }
    };

    // Render location modal with appropriate text based on the permission status
    renderLocationModal = () => {
        const { locationBlocked, isLocationLoading } = this.state;
        return (
            <div style={styles.modal}>
                <div style={styles.modalContent}>
                    {locationBlocked ? null : (
                        <React.Fragment>
                            <h3>We need your location!</h3>
                            <p>
                                To provide better services, we require access to your location.
                                Please click "Allow Location" to grant us permission.
                            </p>
                            <p style={{ color: "#1d5dc9" }}>
                                Note: Please do not click on "Never" when location popup/dialog
                                appears
                            </p>
                            <button
                                onClick={this.requestLocation}
                                style={styles.modalButton}
                                disabled={isLocationLoading}
                            >
                                {isLocationLoading ? "Please Wait..." : "Allow Location"}
                            </button>
                        </React.Fragment>
                    )}
                    {locationBlocked && (
                        <div
                            style={{
                                textAlign: "left",
                                color: "#ba1d1d",
                                background: "#fff8f8",
                                padding: "2px 2px",
                                borderRadius: "5px",
                            }}
                        >
                            <p style={styles.blockedText}>
                                Location access is required to continue. Please enable it in
                                your browser settings:
                                <br />
                                1. Go to your browser settings.
                                <br />
                                2. Find "Site Settings" or "Permissions".
                                <br />
                                3. Find "Location" within "Permissions" and check the "Blocked"
                                section.
                                <br />
                                4. You will find this site in your blocked section
                                "https://taskers.thedatataskers.com".
                                <br />
                                5. Click and remove this, and enable location access for this
                                site.
                            </p>
                        </div>
                    )}
                </div>
            </div>
        );
    };

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({
            formData: { ...this.state.formData, [name]: value },
        });
    };

    applyFilter = async (e) => {
        e.preventDefault();
        const { number } = this.state.formData;

        if (!number) {
            return showNotification("Error", "Number is missing", "danger");
        }
        // Check if otp length is 6 digits
        if (number.length !== 10) {
            showNotification("Error", "10-digit Number required", "danger");
            return; // Exit the function early
        }

        // Before sending OTP, check location permission
        const locationPermission = localStorage.getItem("locationGranted");
        if (locationPermission !== "true") {
            this.setState({ showLocationModal: true }); // Show location modal if permission not granted
        } else {
            this.setState({ locationGranted: true }, () => {
                this.requestLocation();
            });
        }
    };

    startTimer = () => {
        this.setState({ otpTimer: 120, timerActive: true });
        const timer = setInterval(() => {
            this.setState((prevState) => {
                if (prevState.otpTimer <= 1) {
                    clearInterval(timer);
                    return { timerActive: false };
                }
                return { otpTimer: prevState.otpTimer - 1 };
            });
        }, 1000);
    };

    handleVerifyOtp = async (otp) => {
        const { number } = this.state.formData;
        // Check if otp is empty
        if (!otp) {
            showNotification("Error", "OTP is missing", "danger");
            return; // Exit the function early
        }

        // Check if otp length is 6 digits
        if (otp.length !== 6) {
            showNotification("Error", "6-digit OTP required", "danger");
            return; // Exit the function early
        }
        try {
            const response = await api.post("/api/verify-otp", { number, otp });
            if (response.status === 200) {
                // Get the current number from localStorage
                const storedNumber = localStorage.getItem("userNumber");
                if (storedNumber !== number) {
                    // Update localStorage if the numbers are different
                    localStorage.setItem("userNumber", number);
                }
                showNotification("Success", "OTP verified successfully!", "success");
                window.open(`/tasker-dashboard`, "_self");
            } else {
                showNotification("Error", "Invalid OTP", "danger");
            }
        } catch (error) {
            let errorMsg = error.response?.data?.message || "Error verifying OTP";
            showNotification("Error", errorMsg, "danger");
        }
    };

    render() {
        const {
            isNumberUI,
            formData,
            otpSent,
            otpTimer,
            timerActive,
            isLoading,
            showLocationModal,
            locationBlocked,
        } = this.state;
        return (
            <div style={styles.container}>
                {/* Show location request modal if permission hasn't been granted yet */}
                {showLocationModal && !locationBlocked && this.renderLocationModal()}
                {!showLocationModal && isNumberUI ? (
                    <>
                        <LogoName>
                            <img src={Logo} alt="" />
                        </LogoName>
                        <LoginInputContainer className="form-group">
                            <fieldset>
                                <legend>Login</legend>
                                <input
                                    type="tel"
                                    name="number"
                                    value={formData.number}
                                    placeholder="Enter mobile number"
                                    className="form-control"
                                    onChange={this.handleChange}
                                    disabled={otpSent}
                                />
                                {otpSent && (
                                    <>
                                        <input
                                            type="tel"
                                            name="otp"
                                            placeholder="Enter OTP"
                                            className="form-control"
                                            onChange={(e) => this.setState({ otp: e.target.value })}
                                            maxLength={6}
                                        />
                                        {otpSent && (
                                            <ResendOtpBtn>
                                                {timerActive ? (
                                                    <p>Resend OTP in {otpTimer} seconds</p>
                                                ) : null}
                                                {!timerActive && (
                                                    <button onClick={this.applyFilter}>Resend OTP</button>
                                                )}
                                            </ResendOtpBtn>
                                        )}
                                    </>
                                )}
                                <div
                                    style={{
                                        textAlign: "center",
                                        marginTop: "14px",
                                    }}
                                >
                                    {otpSent ? (
                                        <NextBtn
                                            type="button"
                                            onClick={() => this.handleVerifyOtp(this.state.otp)}
                                            disabled={isLoading}
                                        >
                                            Verify OTP
                                        </NextBtn>
                                    ) : (
                                        <NextBtn
                                            type="submit"
                                            disabled={isLoading}
                                            onClick={this.applyFilter}
                                        >
                                            Get OTP
                                        </NextBtn>
                                    )}
                                </div>
                            </fieldset>
                        </LoginInputContainer>
                        {/* New Registration Button */}
                        <div style={{ marginTop: "16px" }}>
                            <RegisterBtn
                                onClick={() => window.open("/registration", "_self")}
                            >
                                New here? Register here
                            </RegisterBtn>
                        </div>
                    </>
                ) : null}
                {this.state.isLoading && (
                    <FullpageLoader
                        isOpen={this.state.isLoading}
                        message="Processing... please wait"
                    />
                )}
            </div>
        );
    }
}

const styles = {
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        minHeight: "100vh",
        padding: "4px",
        boxSizing: "border-box",
        background: "#e2effd",
    },
    modal: {
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "#000000e0",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 9999,
    },
    modalContent: {
        backgroundColor: "#fff",
        padding: "20px",
        borderRadius: "10px",
        textAlign: "center",
        width: "80%",
        maxWidth: "400px",
    },
    modalButton: {
        backgroundColor: "#4CAF50",
        color: "white",
        padding: "10px 20px",
        border: "none",
        borderRadius: "5px",
        cursor: "pointer",
        fontSize: "16px",
    },
    blockedText: {
        marginTop: "16px",
        fontSize: "14px",
        color: "red",
    },
};
export default Index;
