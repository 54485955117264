import React from "react";
import "./App.css";
import AppRouter from "./AppRouter";
import '@fortawesome/fontawesome-free/css/all.min.css';
import { InactivityProvider } from "./InactivityProvider";
const App = () => {
  return (
    <InactivityProvider>
      <div>
        <AppRouter />
      </div>
    </InactivityProvider>
  );
};
export default App;