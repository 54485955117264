import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { showNotification } from "../../actions/index.actions";
import "./TransactionHistory.css";
import api from "../../api";

const TransactionHistory = () => {
  const navigate = useNavigate();
  const [transactions, setTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 1,
    totalRecords: 0,
    hasNextPage: false,
    hasPreviousPage: false,
  });
  const [limit, setLimit] = useState(20);

  const userNumber = localStorage.getItem("userNumber");

  const formatDate = (timestamp) => {
    return new Date(parseInt(timestamp)).toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  const fetchTransactions = async (page, itemsPerPage) => {
    try {
      setIsLoading(true);
      setError("");

      if (!userNumber) {
        showNotification(
          "Authentication Required",
          "Please log in to view your transactions",
          "warning"
        );
        window.open("/", "_self");
        return;
      }

      const response = await api.get(`/tasker-dashboard/transactions`, {
        params: {
          phoneNumber: userNumber,
          page,
          limit: itemsPerPage,
        },
        headers: { "Cache-Control": "no-cache" },
      });

      if (response.status === 404) {
        showNotification(
          "Session Expired",
          "Please login again to continue",
          "warning"
        );
        window.open("/", "_self");
        return;
      }

      setTransactions(response.data.transactions);
      setPagination(response.data.pagination);
    } catch (err) {
      const errorMessage =
        err.response?.data?.message ||
        "Failed to load transaction data. Please try again later.";
      setError(errorMessage);

      if (err.response?.status === 404) {
        showNotification(
          "Session Expired",
          "Please login again to continue",
          "warning"
        );
        window.open("/", "_self");
      } else {
        showNotification("Error", errorMessage, "danger");
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTransactions(pagination.currentPage, limit);
  }, [pagination.currentPage, limit]);

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= pagination.totalPages) {
      fetchTransactions(newPage, limit);
    } else {
      showNotification(
        "Navigation Error",
        "Invalid page number requested",
        "warning"
      );
    }
  };

  const handleLimitChange = (event) => {
    try {
      const newLimit = parseInt(event.target.value);
      setLimit(newLimit);
      fetchTransactions(1, newLimit);
    } catch (error) {
      showNotification("Error", "Failed to update items per page", "danger");
    }
  };

  const handleBack = () => {
    try {
      navigate("/tasker-earning-dashboard");
    } catch (error) {
      showNotification(
        "Navigation Error",
        "Failed to navigate back. Please try again.",
        "danger"
      );
    }
  };

  if (isLoading) {
    return (
      <div className="transaction-container">
        <div className="loading">Loading transaction data...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="transaction-container">
        <div className="error">{error}</div>
      </div>
    );
  }

  return (
    <div className="transaction-container">
      <div className="transaction-header">
        <div className="header-left">
          <button onClick={handleBack} className="back-button">
            ← Back
          </button>
          <h1>Transaction History</h1>
        </div>
        <div className="limit-selector">
          <label>Items per page:</label>
          <select value={limit} onChange={handleLimitChange}>
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
          </select>
        </div>
      </div>

      <div className="transactions-table">
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>Date</th>
              <th>Description</th>
              <th>Amount</th>
              <th>Balance</th>
            </tr>
          </thead>
          <tbody>
            {transactions.map((transaction, index) => (
              <tr key={transaction.id}>
                <td className="serial-number">
                  {(pagination.currentPage - 1) * limit + index + 1}
                </td>
                <td>{formatDate(transaction.createdAt)}</td>
                <td>{transaction.description}</td>
                <td className={`amount ${transaction.txnType.toLowerCase()}`}>
                  {transaction.amount}
                </td>
                <td className="balance">{transaction.balance}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="pagination">
        <button
          onClick={() => handlePageChange(pagination.currentPage - 1)}
          disabled={!pagination.hasPreviousPage}
        >
          Previous
        </button>
        <span className="page-info">
          Page {pagination.currentPage} of {pagination.totalPages}
        </span>
        <button
          onClick={() => handlePageChange(pagination.currentPage + 1)}
          disabled={!pagination.hasNextPage}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default TransactionHistory;
