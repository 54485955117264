import React from "react";
import { useState, useEffect, useCallback } from "react";
import LoadingState from "./components/TaskDashboardComponents/LoadingState";
import ErrorState from "./components/TaskDashboardComponents/ErrorState";
import NoDataState from "./components/TaskDashboardComponents/NoDataState";
import EnhancedAudioPlayer from "./components/TaskDashboardComponents/AudioPlayer";
import RatingSection from "./components/TaskDashboardComponents/RatingSection";
import TranscriptSection from "./components/TaskDashboardComponents/TranscriptSection";
import CommentsSection from "./components/TaskDashboardComponents/CommentSection";
import TaskerProfilesSelect from "./components/TaskDashboardComponents/TaskerProfilesSelect";
import { format } from "date-fns";
import ValidationErrorModal from "./components/TaskDashboardComponents/ValidationErrorModal";
import api from "./api";

const translations = {
  en: {
    taskContent: "Task Content",
    guidelines: "Guidelines",
    taskDetails: "Task Details",
    taskDescription: "Task Description",
    sampleDescription:
      "This is a sample task description. It provides a brief overview of what needs to be done.",
    language: "Language",
    assignedOn: "Assigned On",
    taskType: "Task Type",
    completedOn: "Completed on",
    try: "Try",
    taskerDetails: "Tasker Details",
    avgRating: "Avg Rating",
    gender: "Gender",
    age: "Age",
    location: "Location",
    profession: "Profession",
    highestEducation: "Highest Education",
    device: "Device",
    fos: "FOS",
    rating: "Rating",
    content: "Content",
    voiceClarity: "Voice/Clarity",
    audibility: "Audibility",
    backgroundNoise: "Background noise",
    transcript: "Transcript",
    writeTranscription: "Write the transcription here:",
    enterTranscript: "Enter the transcript text here...",
    comments: "Comments",
    selectComment: "Select a comment",
    audio: "Audio",
    clarity: "Clarity",
    background: "Background",
    typeAdditionalComments: "Type additional comments here...",
    previous: "Previous",
    reject: "Reject",
    postpone: "Postpone",
    transfer: "Transfer",
    accept: "Accept",
    next: "Next",
  },
  // Add more languages here
};

const getTranslation = (lang, key) => {
  return translations[lang][key] || key;
};

const TaskerDashboard = ({ initialEtaId }) => {
  const [language, setLanguage] = useState("en");

  const [dashboardData, setDashboardData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [nextCursor, setNextCursor] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [totalFetched, setTotalFetched] = useState(0);

  const [ratings, setRatings] = useState({});
  const [transcript, setTranscript] = useState("");
  const [selectedComment, setSelectedComment] = useState("");
  const [additionalComment, setAdditionalComment] = useState("");
  const [selectedProfiles, setSelectedProfiles] = useState([]);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [playbackData, setPlaybackData] = useState(null);

  const [validationErrors, setValidationErrors] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);

  const [ratingParameters, setRatingParameters] = useState([]);

  const handlePlaybackDataChange = useCallback((data) => {
    setPlaybackData(data);
  }, []);

  const validateForm = useCallback(() => {
    const errors = {};

    // Check ratings against fetched parameters
    if (ratingParameters.length > 0) {
      const missingRatings = ratingParameters.filter((param) => {
        const rating = ratings[param.CriteriaKey];
        return !rating || rating.value === 0;
      });

      if (missingRatings.length > 0) {
        errors.ratings = `Please provide ratings for: ${missingRatings
          .map((param) => param.CriteriaName)
          .join(", ")}`;
      }
    }

    // Check comment selection
    if (!selectedComment) {
      errors.comments = "Please select a comment option";
    }

    // Check profile selection
    if (selectedProfiles.length === 0) {
      errors.profiles = "Please select at least one profile";
    }

    setValidationErrors(errors);
    const isValid = Object.keys(errors).length === 0;
    setIsFormValid(isValid);

    return isValid;
  }, [ratings, selectedComment, selectedProfiles, ratingParameters]);

  useEffect(() => {
    validateForm();
  }, [
    ratings,
    selectedComment,
    selectedProfiles,
    ratingParameters,
    validateForm,
  ]);

  useEffect(() => {
    const fetchRatingParameters = async () => {
      try {
        const response = await api.get("/tasker-dashboard/rating-parameters", {
          params: { contentType: "audio" },
        });
        setRatingParameters(response.data);
      } catch (error) {
        // console.error("Error fetching rating parameters:", error);
      }
    };

    fetchRatingParameters();
  }, []);

  const t = (key) => getTranslation(language, key);

  const fetchDashboardData = useCallback(
    async (cursor = null) => {
      if (!hasMore) return;

      try {
        setLoading(true);
        setError(null);

        const response = await api.get("/tasker-dashboard", {
          params: { cursor, limit: 20 },
        });

        const { data, nextCursor } = response.data;
        // console.log(data);

        if (data.length > 0) {
          setDashboardData((prevData) => {
            const newData = data.filter(
              (item) =>
                !prevData.some(
                  (prevItem) => prevItem.taskResultID === item.taskResultID
                )
            );
            return [...prevData, ...newData];
          });
          setTotalFetched((prev) => prev + data.length);
          setNextCursor(nextCursor);
          setHasMore(!!nextCursor);
        } else {
          setHasMore(false);
        }
      } catch (err) {
        // console.error("Error fetching dashboard data:", err);
        setError(`Failed to fetch dashboard data: ${err.message}`);
      } finally {
        setLoading(false);
      }
    },
    [hasMore]
  );

  const handleSubmit = async () => {
    if (!validateForm()) {
      setIsErrorModalOpen(true);
      return;
    }

    setIsSubmitting(true);
    try {
      const currentData = dashboardData[currentIndex];

      const reviewData = {
        taskResultId: currentData.taskResultId,
        raterUserId: 1, // Hardcoded for now, replace with actual rater ID when available
        performerUserId: currentData.taskerDetails.taskerId,
        ratings: Object.values(ratings).map(({ criteriaId, value }) => ({
          criteriaId,
          value,
        })),
        transcript: transcript,
        comment: {
          selectedOption: selectedComment,
          additionalComment: additionalComment,
        },
        selectedProfiles: selectedProfiles.map((profile) => ({
          id: profile.id,
        })),
      };

      const response = await api.post(
        "/tasker-dashboard/submit-review",
        reviewData
      );

      if (playbackData && playbackData.totalDuration > 0) {
        const formattedDateTime = format(
          new Date(playbackData.lastPlayedTimestamp),
          "yyyyMMddHHmmss"
        );
        const mediaDurationData = {
          taskResultId: currentData.taskResultId,
          mediaType: "audio",
          totalMediaDuration: playbackData.totalDuration,
          maxListenedDuration: playbackData.maxListenedDuration,
          listenedDateTime: formattedDateTime,
        };

        const mediaDurationResponse = await api.post(
          "/tasker-dashboard/store-media-duration",
          mediaDurationData
        );

        if (mediaDurationResponse.status !== 200) {
          throw new Error(
            mediaDurationResponse.data.error ||
              "Failed to store media duration data"
          );
        }
      } else {
        // console.warn("Invalid playback data, not submitting media duration");
      }

      if (response.status === 200) {
        // console.log("Review submitted successfully");
        // Clear the form data
        setRatings({});
        setTranscript("");
        setSelectedComment("");
        setAdditionalComment("");
        // Move to the next task
        handleNavigation("next");
      } else {
        throw new Error(response.data.error || "Failed to submit review");
      }
      // console.log("The revie data: ", reviewData);
    } catch (error) {
      // console.error("Error submitting review:", error.message);
      if (error.response) {
        // console.error("Response data:", error.response.data);
        // console.error("Response status:", error.response.status);
        // console.error("Response headers:", error.response.headers);
      } else if (error.request) {
        // console.error("Request:", error.request);
      } else {
        // console.error("Error details:", error);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleTransfer = async () => {
    try {
      const currentData = dashboardData[currentIndex];
      const response = await api.post("/tasker-dashboard/update-task-status", {
        taskResultId: currentData.taskResultId,
        newStatus: 4, // Transfer status
      });

      if (response.status === 200) {
        // console.log("Task transfer requested successfully");
        handleNavigation("next");
      } else {
        throw new Error(response.data.error || "Failed to request transfer");
      }
    } catch (error) {
      // console.error("Error requesting transfer:", error);
      // Handle the error (e.g., show an error message to the user)
    }
  };

  const handlePostpone = async () => {
    const days = prompt("Enter number of days to postpone (max 10):", "1");
    if (days === null) return; // User cancelled

    const postponeDays = parseInt(days, 10);
    if (isNaN(postponeDays) || postponeDays < 1 || postponeDays > 10) {
      alert("Please enter a valid number between 1 and 10.");
      return;
    }

    try {
      const currentData = dashboardData[currentIndex];
      const response = await api.post("/tasker-dashboard/update-task-status", {
        taskResultId: currentData.taskResultId,
        newStatus: 2, // Postponed status
        postponeDays,
      });

      if (response.status === 200) {
        // console.log("Task postponed successfully");
        handleNavigation("next");
      } else {
        throw new Error(response.data.error || "Failed to postpone task");
      }
    } catch (error) {
      // console.error("Error postponing task:", error);
      // Handle the error (e.g., show an error message to the user)
    }
  };

  const handleReject = async () => {
    if (!validateForm()) {
      setIsErrorModalOpen(true);
      return;
    }

    try {
      const currentData = dashboardData[currentIndex];

      if (!selectedComment) {
        alert("Please select a comment before rejecting the task.");
        return;
      }

      const rejectData = {
        taskResultId: currentData.taskResultId,
        raterUserId: 1,
        performerUserId: currentData.taskerDetails.taskerId,
        selectedComment: selectedComment,
        additionalComment: additionalComment,
        ratings: Object.values(ratings).map(({ criteriaId, value }) => ({
          criteriaId,
          value,
        })),
        selectedProfiles: selectedProfiles.map((profile) => ({
          id: profile.id,
        })),
      };

      // console.log("Reject data:", rejectData);

      const response = await api.post(
        "/tasker-dashboard/reject-task",
        rejectData
      );

      if (response.status === 200) {
        // console.log("Task rejected successfully");
        // Clear all form data
        setSelectedComment("");
        setAdditionalComment("");
        setRatings({});
        setSelectedProfiles([]);
        // Move to the next task
        handleNavigation("next");
      } else {
        throw new Error(response.data.error || "Failed to reject task");
      }
    } catch (error) {
      // console.error("Error rejecting task:", error);
      if (error.response) {
        // console.error("Response data:", error.response.data);
        // console.error("Response status:", error.response.status);
        // console.error("Response headers:", error.response.headers);
        alert(
          `Failed to reject task: ${
            error.response.data.error || "Unknown error"
          }`
        );
      } else if (error.request) {
        // console.error("Request:", error.request);
        alert("Failed to reject task: No response received from server");
      } else {
        // console.error("Error details:", error);
        alert(`Failed to reject task: ${error.message}`);
      }
    }
  };

  useEffect(() => {
    fetchDashboardData();
  }, [fetchDashboardData]);

  const handleNavigation = (direction) => {
    if (direction === "next") {
      if (currentIndex < dashboardData.length - 1) {
        setCurrentIndex(currentIndex + 1);
      } else if (hasMore) {
        fetchDashboardData(nextCursor).then(() => {
          setCurrentIndex(currentIndex + 1);
        });
      }
    } else if (direction === "previous" && currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  if (loading && dashboardData.length === 0) return <LoadingState />;
  if (error) return <ErrorState error={error} />;
  if (dashboardData.length === 0) return <NoDataState />;

  const currentData = dashboardData[currentIndex];

  // Early return if currentData is undefined
  if (!currentData) {
    return <NoDataState />;
  }

  const { taskContent, taskDetails, taskerDetails } = currentData;

  const isLastRecord = currentIndex === dashboardData.length - 1 && !hasMore;

  return (
    <div style={styles.taskerDashboard}>
      <div style={styles.mainContent}>
        <div style={styles.taskContent}>
          <div style={styles.taskContentHeader}>
            <h2 style={styles.heading}>{t("taskContent")}</h2>
            <button style={styles.guidelinesButton}>{t("guidelines")}</button>
          </div>
          <div style={styles.audioPlayerContainer}>
            <EnhancedAudioPlayer
              src={taskContent.taskUrl}
              style={styles.audioPlayer}
              onPlaybackDataChange={handlePlaybackDataChange}
            />
          </div>
        </div>
        <div style={styles.detailsColumn}>
          <div style={styles.taskDetails}>
            <h2 style={styles.heading}>{t("taskDetails")}</h2>
            <div style={styles.taskDescription}>
              <h3 style={styles.subheading}>{t("taskDescription")}</h3>
              <p style={styles.descriptionText}>
                {taskDetails.taskDescription}
              </p>
            </div>
            <div style={styles.taskInfo}>
              <div>
                <span style={styles.label}>{t("language")}:</span>
                <span style={styles.labelValue}>{taskDetails.language}</span>
              </div>
              <div>
                <span style={styles.label}>{t("assignedOn")}:</span>
                <span style={styles.labelValue}>{taskDetails.assignedOn}</span>
              </div>
              <div>
                <span style={styles.label}>{t("taskType")}:</span>
                <span style={styles.labelValue}>{taskDetails.taskType}</span>
              </div>
              <div>
                <span style={styles.label}>{t("completedOn")}:</span>
                <span style={styles.labelValue}>
                  {taskDetails.completedOn || "Not completed"}
                </span>
              </div>
              <div>
                <span style={styles.label}>{t("try")}:</span>
                <span style={styles.labelValue}>{taskDetails.try}</span>
              </div>
            </div>
          </div>
          <div style={styles.taskerDetails}>
            <h2 style={styles.heading}>{t("taskerDetails")}</h2>
            <div style={styles.taskerInfo}>
              <div style={styles.column}>
                <div style={styles.field}>
                  <span style={styles.label}>{t("avgRating")}:</span>
                  <span style={styles.labelValue}>
                    {taskerDetails.avgRating}
                  </span>
                </div>
                <div style={styles.field}>
                  <span style={styles.label}>{t("age")}:</span>
                  <span style={styles.labelValue}>{taskerDetails.age}</span>
                </div>
                <div style={styles.field}>
                  <span style={styles.label}>{t("gender")}:</span>
                  <span style={styles.labelValue}>{taskerDetails.gender}</span>
                </div>
                <div style={styles.field}>
                  <span style={styles.label}>{t("location")}:</span>
                  <span style={styles.labelValue}>
                    {taskerDetails.location}
                  </span>
                </div>
              </div>
              <div style={styles.column}>
                <div style={styles.field}>
                  <span style={styles.label}>{t("language")}:</span>
                  <span style={styles.labelValue}>
                    {taskerDetails.language}
                  </span>
                </div>
                <div style={styles.field}>
                  <span style={styles.label}>{t("profession")}:</span>
                  <span style={styles.labelValue}>
                    {taskerDetails.profession}
                  </span>
                </div>
                <div style={styles.field}>
                  <span style={styles.label}>{t("highestEducation")}:</span>
                  <span style={styles.labelValue}>
                    {taskerDetails.highestEducation}
                  </span>
                </div>
                <div style={styles.field}>
                  <span style={styles.label}>{t("device")}:</span>
                  <span style={styles.labelValue}>{taskerDetails.device}</span>
                </div>
                <div style={styles.field}>
                  <span style={styles.label}>{t("fos")}:</span>
                  <span style={styles.labelValue}>{taskerDetails.fos}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={styles.secondaryContent}>
        <RatingSection
          ratings={ratings}
          onRatingChange={setRatings}
          t={t}
          ratingParameters={ratingParameters}
          setRatingParameters={setRatingParameters}
        />
        <TranscriptSection
          transcript={transcript}
          setTranscript={setTranscript}
          t={t}
        />
      </div>
      <div style={styles.tertiaryContent}>
        <CommentsSection
          selectedComment={selectedComment}
          setSelectedComment={setSelectedComment}
          additionalComment={additionalComment}
          setAdditionalComment={setAdditionalComment}
          t={t}
        />
        <TaskerProfilesSelect
          mediaType={taskContent?.mediaType || "audio"}
          selectedProfiles={selectedProfiles}
          setSelectedProfiles={setSelectedProfiles}
          t={t}
        />
      </div>
      <div style={styles.navigation}>
        <button
          style={{
            ...styles.button,
            ...(currentIndex === 0 ? styles.disabledButton : {}),
          }}
          onClick={() => handleNavigation("previous")}
          disabled={currentIndex === 0}
        >
          {t("previous")}
        </button>
        <div style={styles.actionButtons}>
          <button
            style={{ ...styles.button, ...styles.rejectButton }}
            onClick={handleReject}
          >
            {t("reject")}
          </button>
          <button
            style={{ ...styles.button, ...styles.postponeButton }}
            onClick={handlePostpone}
          >
            {t("postpone")}
          </button>
          <button
            style={{ ...styles.button, ...styles.transferButton }}
            onClick={handleTransfer}
          >
            {t("transfer")}
          </button>
          <button
            style={{ ...styles.button, ...styles.acceptButton }}
            onClick={handleSubmit}
            disabled={isSubmitting}
          >
            {isSubmitting ? t("submitting") : t("accept")}
          </button>
        </div>
        <button
          style={{
            ...styles.button,
            ...(isLastRecord ? styles.disabledButton : {}),
          }}
          onClick={() => handleNavigation("next")}
          disabled={isLastRecord}
        >
          {t("next")}
        </button>
      </div>
      <ValidationErrorModal
        isOpen={isErrorModalOpen}
        onClose={() => setIsErrorModalOpen(false)}
        errors={validationErrors}
      />
    </div>
  );
};

const styles = {
  guidelinesButton: {
    padding: "0.25rem 0.5rem",
    border: "none",
    borderRadius: "4px",
    fontSize: "0.875rem",
    fontWeight: "600",
    cursor: "pointer",
    backgroundColor: "#4299e1",
    color: "white",
    transition: "background-color 0.3s",
  },
  detailsColumn: {
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
  },
  taskDetails: {
    border: "1px solid #e2e8f0",
    padding: "0.5rem",
    flex: "1 1 50%",
    overflowY: "none",
  },

  descriptionText: {
    marginTop: "0.25rem",
    fontSize: "0.875rem",
  },
  comments: {
    width: "30%",
  },
  commentInputs: {
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
  },
  select: {
    padding: "0.25rem",
    border: "1px solid #e2e8f0",
    borderRadius: "4px",
    fontSize: "0.875rem",
    width: "100%",
  },
  textarea: {
    padding: "0.25rem",
    border: "1px solid #e2e8f0",
    borderRadius: "4px",
    fontSize: "0.875rem",
    minHeight: "4rem",
    resize: "vertical",
    width: "100%",
  },
  rejectButton: {
    backgroundColor: "#e53e3e",
  },
  postponeButton: {
    backgroundColor: "#ed8936",
  },
  transferButton: {
    backgroundColor: "#38a169",
  },
  acceptButton: {
    backgroundColor: "#38a169",
  },

  actionButtons: {
    display: "flex",
    gap: "0.25rem",
  },
  heading: {
    fontSize: "1.25rem",
    fontWeight: 600,
    marginBottom: "0.15rem",
  },
  subheading: {
    fontWeight: 600,
    fontSize: "1rem",
  },
  taskInfo: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "1rem",
    fontSize: "0.875rem",
    marginTop: "0.25rem",
  },
  transcriptDescription: {
    fontSize: "0.875rem",
    marginBottom: "0.5rem",
  },
  transcriptBox: {
    border: "1px solid #e2e8f0",
    borderRadius: "4px",
    padding: "0.5rem",
    fontSize: "0.875rem",
    width: "90%",
    height: "calc(100% - 4rem)",
    resize: "none",
    overflowY: "auto",
  },
  fullWidthInfo: {
    gridColumn: "1 / -1",
    display: "flex",
    alignItems: "flex-start",
  },

  leftColumn: {
    flex: "1 1 50%",
    display: "flex",
    flexDirection: "column",
    gap: "0.25rem",
  },
  rightColumn: {
    flex: "1 1 50%",
    display: "flex",
    flexDirection: "column",
    gap: "0.25rem",
  },

  button: {
    padding: "0.25rem 0.5rem",
    border: "none",
    borderRadius: "4px",
    fontSize: "0.875rem",
    fontWeight: "600",
    cursor: "pointer",
    transition: "background-color 0.3s, transform 0.1s",
    outline: "none",
    boxShadow: "0 1px 2px rgba(0, 0, 0, 0.1)",
    backgroundColor: "#3182ce",
    color: "white",
  },
  disabledButton: {
    backgroundColor: "#a0aec0",
    cursor: "not-allowed",
    opacity: 0.6,
    boxShadow: "none",
  },

  locationContainer: {
    display: "flex",
    alignItems: "center",
    borderRadius: "8px",
  },
  locationText: {
    margin: 0,
    marginLeft: "0.5rem",
    fontSize: "0.875rem",
    color: "#2c5282",
  },

  taskContent: {
    border: "1px solid #e2e8f0",
    padding: "1rem",
    backgroundColor: "#f7fafc",
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    display: "flex",
    flexDirection: "column",
    height: "90%", // Make it full height
  },
  taskContentHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "1rem",
  },
  audioPlayerContainer: {
    flex: 1, // This will make it take up all available space
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#edf2f7",
    borderRadius: "8px",
    padding: "1rem",
  },
  audioPlayer: {
    width: "100%",
    maxWidth: "500px", // You can adjust this value as needed
  },
  taskerDetails: {
    border: "1px solid #e2e8f0",
    padding: "0.5rem",
    flex: "1 1 50%",
    overflowY: "auto",
  },
  taskerInfo: {
    display: "flex",
    gap: "1rem",
    fontSize: "0.875rem",
  },
  column: {
    flex: "1 1 50%",
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
  },
  field: {
    display: "flex",
    alignItems: "flex-start",
  },
  label: {
    fontWeight: "bold",
    marginRight: "0.5rem",
    minWidth: "150px", // Increased to accommodate longer labels
    flexShrink: 0,
  },
  labelValue: {
    backgroundColor: "rgba(66, 153, 225, 0.1)",
    padding: "0.25rem 0.5rem",
    borderRadius: "6px",
    whiteSpace: "pre-wrap",
    wordBreak: "break-word",
    flex: 1,
  },

  secondaryContent: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "1rem",
    marginTop: "0rem",
    minHeight: "80%",
  },

  tertiaryContent: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "1rem",
    marginTop: "1rem",
    minHeight: "80%",
  },

  taskerDashboard: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    backgroundColor: "white",
    color: "black",
    padding: "0.5rem",
    boxSizing: "border-box",
    gap: "0.25rem",
  },

  // Update mainContent height
  mainContent: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "1rem",
    minHeight: "40vh",
  },

  // Update navigation margin
  navigation: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "auto",
    paddingTop: "1rem",
  },
};

export default TaskerDashboard;
