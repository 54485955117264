import React from "react";
import ReactModal from "react-modal";
import PropTypes from "prop-types";
const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        width: "auto",
        maxWidth: "95vw",
        overflow: "hidden",
        maxHeight: "95vh",
        backgroundColor: "white",
        border: "none",
        height: "auto",
        zIndex: 2,
        padding: "10px",
    },
    overlay: {
        backgroundColor: "rgba(0, 0, 0, 0.8)",
        zIndex: 1,
    },
};

/**
 * @author Gulam Hussain
 * @param {*} props
 */
function Modal(props) {
    const style = getStyle(props.customStyles);
    const hideHeader = props.hideHeader ? true : false;
    return (
        <ReactModal
            shouldCloseOnOverlayClick={props.shouldCloseOnOverlayClick}
            shouldCloseOnEsc={props.shouldCloseOnEsc}
            isOpen={props.isOpen}
            style={style}
            ariaHideApp={false}>
            <div className="op-react-modal" style={getModalContainerStyles(props.modelContainerStyles, hideHeader)}>
                {props.hideClosebtn ? null :
                    <div
                        className="op-react-modal-header"
                        style={{
                            borderBottom: props.divider ? "1px solid #ccc" : "none",
                            display: hideHeader ? "none" : "flex",
                        }}>
                        <h4 className="op-react-modal-title">{props.title}</h4>
                        {props.hideClosebtn ? null : (
                            <div onClick={props.onClose} className="op-react-modal-close-btn">
                                <i className="fa fa-times" />
                            </div>
                        )}
                    </div>}
                <div style={getModalBodyStyles(props.modelBodyStyles)} className="op-react-modal-body">
                    {props.children}
                </div>
            </div>
        </ReactModal>
    );
}
export default Modal;
Modal.propTypes = {
    onClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
};

function getStyle(userStyle) {
    let contentStyle = customStyles.content;
    if (userStyle && userStyle.content) {
        contentStyle = Object.assign({}, contentStyle, userStyle.content);
    }
    let overlayStyle = customStyles.overlay;
    if (userStyle && userStyle.overlay) {
        overlayStyle = Object.assign({}, overlayStyle, userStyle.overlay);
    }
    return {
        content: contentStyle,
        overlay: overlayStyle,
    };
}

function getModalContainerStyles(styles, hideHeader) {
    if (styles) {
        return styles;
    }
    return { padding: hideHeader ? "25px" : "0", height: "100%" };
}
function getModalBodyStyles(styles) {
    if (styles) {
        return styles;
    }
    return { padding: "0" };
}
