import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import RegistrationPage from "./Registration_Page";
// import EmployeePage from "./EmployeePage"; // Create this page
// import IndividualTaskerPage from "./IndividualTaskerPage"; // Create this page
// import CorporateTaskerPage from "./CorporateTaskerPage"; // Create this page
// import DataCustomerPage from "./DataCustomerPage"; // Create this page
// import FranchiseTaskerPage from "./FranchiseTaskerPage"; // Create this page
//import BlankPage from "./BlankPage"; // Create this page
//import PrivateRoute from "./PrivateRoute";
import AudioWorkFlow from "./Survey/index";
import Dashboard from "./Survey/Dashboard";
import TaskerLogin from "./Survey/TaskerLogin";

import TaskerDashboardPage from "./TaskerDashboardPage";
import RatingsDashboard from "./components/UserDashboard/UserRatingsDashboard";
import TaskerEarningsDashboard from "./components/UserDashboard/TaskerEarningsPage";
import TransactionHistory from "./components/UserDashboard/TransactionHistory";
import PaymentForm from "./components/UserDashboard/TakserPaymentForm";
import ReferralShare from "./components/TaskDashboardComponents/ReferralShareComponent";
import ReferralLanding from "./components/TaskDashboardComponents/ReferralLanding";

function App() {
  return (
    <Router>
      <Routes>
        {/* <Route path="/" element={<BlankPage />} /> */}
        <Route path="/registration" element={<RegistrationPage />} />
        <Route path="/" element={<TaskerLogin />} />
        <Route path="/tasker-dashboard" element={<Dashboard />} />
        <Route path="/audio-task" element={<AudioWorkFlow />} />
        {/* <Route
          path="/employee"
          element={
            <PrivateRoute
              element={EmployeePage}
              allowedUserTypes={["Employee"]}
            />
          }
        />
        <Route
          path="/individual-tasker"
          element={
            <PrivateRoute
              element={IndividualTaskerPage}
              allowedUserTypes={["IndividualTasker"]}
            />
          }
        />
        <Route
          path="/corporate-tasker"
          element={
            <PrivateRoute
              element={CorporateTaskerPage}
              allowedUserTypes={["CorporateTasker"]}
            />
          }
        />
        <Route
          path="/data-customer"
          element={
            <PrivateRoute
              element={DataCustomerPage}
              allowedUserTypes={["DataCustomer"]}
            />
          }
        />
        <Route
          path="/franchise-tasker"
          element={
            <PrivateRoute
              element={FranchiseTaskerPage}
              allowedUserTypes={["FranchiseTasker"]}
            />
          }
        /> */}
        <Route path="*" element={<Navigate to="/" />} />
        {/* This route is for employee */}
        <Route path="/employee-dashboard" element={<TaskerDashboardPage />} />

        <Route path="/tasker-rating-dashboard" element={<RatingsDashboard />} />
        <Route
          path="/tasker-earning-dashboard"
          element={<TaskerEarningsDashboard />}
        />
        <Route path="/transaction-history" element={<TransactionHistory />} />
        <Route path="/tasker-payment-form" element={<PaymentForm />} />
        <Route path="/tasker-referral-page" element={<ReferralShare />} />
        <Route path="/details" element={<ReferralLanding />} />
      </Routes>
    </Router>
  );
}

export default App;
