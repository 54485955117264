import React, { useState, useEffect } from "react";
import api from "../../api";
import "./ReferralShareStyles.css";
import FullpageLoader from "../../elements/FullpageLoader";
import Whatsapp from "../../Image/whatsapp.png";
import Facebook from "../../Image/facebook.png";
import Gmail from "../../Image/gmail.png";
import Telegram from "../../Image/telegram.png";
import Twitter from "../../Image/twitter.png";
import Linkedin from "../../Image/linkedin.png";
import SMS from "../../Image/sms.png";
import CopyIcon from "../../Image/layer.png";
import { showNotification } from "../../actions/index.actions";
import ShareModal from "./ShareModal";

const ReferralShare = () => {
  const [loading, setLoading] = useState(false);
  const [referralData, setReferralData] = useState({ referrerUrl: "" });
  const [showModal, setShowModal] = useState(false);
  const [shareMessage, setShareMessage] = useState("");
  const userNumber = localStorage.getItem("userNumber");
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

  const getReferralMessage = (url) => {
    return `Hey! 👋 I'm using The Data Taskers app to earn extra money by completing simple tasks. It's fun, flexible, and rewarding! 🎉 You can join too - just use this referral link: ${url} 😊`;
  };

  const handleShare = async (mode) => {
    try {
      setLoading(true);

      const response = await api.post(
        "/referral/share",
        {
          phoneNumber: userNumber,
          mode,
        },
        {
          headers: { "Cache-Control": "no-cache" },
        }
      );

      if (response.data.success) {
        const referrerUrl = response.data.data.referrerUrl;
        setReferralData({ referrerUrl });
        const fullMessage = getReferralMessage(referrerUrl);

        if (mode === "copy") {
          setShareMessage(fullMessage);
          setShowModal(true);
        } else {
          handleSocialShare(mode, fullMessage, referrerUrl);
        }
      }
    } catch (err) {
      console.error("Share failed:", err);
      showNotification(
        "Error",
        err.response?.data?.message || "Failed to share. Please try again.",
        "danger"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleSocialShare = (mode, fullMessage, referrerUrl) => {
    const shareUrls = {
      whatsapp: isIOS
        ? `https://wa.me/?text=${encodeURIComponent(fullMessage)}`
        : `whatsapp://send?text=${encodeURIComponent(fullMessage)}`,
      facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        referrerUrl
      )}&quote=${encodeURIComponent(fullMessage)}`,
      telegram: isIOS
        ? `https://t.me/share/url?url=${encodeURIComponent(
            referrerUrl
          )}&text=${encodeURIComponent(fullMessage)}`
        : `tg://msg?text=${encodeURIComponent(fullMessage)}`,
      twitter: `https://twitter.com/intent/tweet?text=${encodeURIComponent(
        fullMessage
      )}`,
      linkedin: `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
        referrerUrl
      )}`,
      email: `mailto:?subject=${encodeURIComponent(
        "Join The Data Taskers!"
      )}&body=${encodeURIComponent(fullMessage)}`,
      sms: isIOS
        ? `sms:&body=${encodeURIComponent(fullMessage)}`
        : `sms:?body=${encodeURIComponent(fullMessage)}`,
    };

    const url = shareUrls[mode];
    if (url) {
      if (isIOS) {
        window.location.href = url;
        if (mode === "whatsapp" || mode === "telegram") {
          setTimeout(() => window.open(url, "_blank"), 500);
        }
      } else {
        try {
          window.open(url, "_blank");
        } catch (err) {
          window.location.href = url;
        }
      }
    }
  };

  if (loading) {
    return <FullpageLoader isOpen={loading} />;
  }
  return (
    <div className="referral-page">
      <div className="referral-container">
        <div className="referral-header">
          <div className="header-content">
            <h1 className="text-3xl font-bold text-primary">
              Invite Friends & Family
            </h1>
            <h2 className="text-xl font-semibold text-secondary mt-4">
              Start Sharing Now!
            </h2>
            <p className="header-description">
              Invite friends and family to unlock amazing rewards! Share the app
              and start earning together!
            </p>
          </div>
          <div className="share-section">
            <h2>Share Your Invitation Link</h2>

            <div className="share-grid">
              <button
                onClick={() => handleShare("copy")}
                className="share-button copy"
                disabled={loading}
              >
                <img src={CopyIcon} alt="" />
                <span>Copy Link</span>
              </button>

              <button
                onClick={() => handleShare("whatsapp")}
                className="share-button whatsapp"
                disabled={loading}
              >
                <img src={Whatsapp} alt="" />
                <span>WhatsApp</span>
              </button>

              <button
                onClick={() => handleShare("facebook")}
                className="share-button facebook"
                disabled={loading}
              >
                <img src={Facebook} alt="" />
                <span>Facebook</span>
              </button>

              <button
                onClick={() => handleShare("twitter")}
                className="share-button twitter"
                disabled={loading}
              >
                <img src={Twitter} alt="" />
                <span>Twitter</span>
              </button>

              <button
                onClick={() => handleShare("telegram")}
                className="share-button telegram"
                disabled={loading}
              >
                <img src={Telegram} alt="" />
                <span>Telegram</span>
              </button>

              <button
                onClick={() => handleShare("linkedin")}
                className="share-button linkedin"
                disabled={loading}
              >
                <img src={Linkedin} alt="" />
                <span>LinkedIn</span>
              </button>

              <button
                onClick={() => handleShare("email")}
                className="share-button email"
                disabled={loading}
              >
                <img src={Gmail} alt="" style={{ width: "50px" }} />
                <span>Email</span>
              </button>

              <button
                onClick={() => handleShare("sms")}
                className="share-button sms"
                disabled={loading}
              >
                <img src={SMS} alt="" />
                <span>SMS</span>
              </button>
            </div>
          </div>
        </div>
        <div className="benefits-section">
          <h3>How It Works</h3>
          <ul className="benefits-list">
            <li>
              <strong>Step 1:</strong> Invite people using your unique link.
              <span>🔗👨‍👨‍👧‍👧</span>
            </li>
            <li>
              <strong>Step 2:</strong> They download and join the app through
              your invitation.<span>📱👥</span>
            </li>
            <li>
              <strong>Step 3:</strong> They start using the app and complete
              tasks.<span>✅📝</span>
            </li>
            <li>
              <strong>Step 4:</strong> After their 7 days of active usage and
              task completion..<span>⌛📅</span>
            </li>
            <li>
              <strong>Step 5:</strong> You will receive 500 Tasker Sicca for
              every successful invite!<span>🎉💰</span>
            </li>
          </ul>
        </div>
        <div className="reward-note">
          <p>
            <strong>Note:</strong> Your rewards will be credited once your
            invitee has:
          </p>
          <ul>
            <li>Used the app for at least 7 days</li>
            <li>Completed the assigned tasks successfully</li>
          </ul>
        </div>
      </div>
      {/* <div className="share-section">
        <h2>Share Your Invitation Link</h2>

        {showCopiedAlert && (
          <div className="copied-alert">
            Full invitation message copied to clipboard!
          </div>
        )}

        <div className="share-grid">
          <button
            onClick={() => handleShare("copy")}
            className="share-button copy"
            disabled={loading}
          >
            <span className="icon">📋</span>
            <span>Copy Message</span>
          </button>

          <button
            onClick={() => handleShare("whatsapp")}
            className="share-button whatsapp"
            disabled={loading}
          >
            <span className="icon">📱</span>
            <span>WhatsApp</span>
          </button>

          <button
            onClick={() => handleShare("facebook")}
            className="share-button facebook"
            disabled={loading}
          >
            <span className="icon">👥</span>
            <span>Facebook</span>
          </button>

          <button
            onClick={() => handleShare("twitter")}
            className="share-button twitter"
            disabled={loading}
          >
            <span className="icon">🐦</span>
            <span>Twitter</span>
          </button>

          <button
            onClick={() => handleShare("telegram")}
            className="share-button telegram"
            disabled={loading}
          >
            <span className="icon">📬</span>
            <span>Telegram</span>
          </button>

          <button
            onClick={() => handleShare("linkedin")}
            className="share-button linkedin"
            disabled={loading}
          >
            <span className="icon">💼</span>
            <span>LinkedIn</span>
          </button>

          <button
            onClick={() => handleShare("email")}
            className="share-button email"
            disabled={loading}
          >
            <span className="icon">✉️</span>
            <span>Email</span>
          </button>

          <button
            onClick={() => handleShare("sms")}
            className="share-button sms"
            disabled={loading}
          >
            <span className="icon">💌</span>
            <span>SMS</span>
          </button>
        </div>
    </div> */}
      {/* <div className="benefits-section">
        <h3>How It Works</h3>
        <ul className="benefits-list">
          <li>
            <strong>Step 1:</strong> Invite people using your unique link.
            <span>🔗👨‍👨‍👧‍👧</span>
          </li>
          <li>
            <strong>Step 2:</strong> They download and join the app through
            your invitation.<span>📱👥</span>
          </li>
          <li>
            <strong>Step 3:</strong> They start using the app and complete
            tasks.<span>✅📝</span>
          </li>
          <li>
            <strong>Step 4:</strong> After their 7 days of active usage and
            task completion..<span>⌛📅</span>
          </li>
          <li>
            <strong>Step 5:</strong> You will receive 500 Tasker Sicca for
            every successful invite!<span>🎉💰</span>
          </li>
        </ul>

        <div className="reward-note">
          <p>
            <strong>Note:</strong> Your rewards will be credited once your
            invitee has:
          </p>
          <ul>
            <li>Used the app for at least 7 days</li>
            <li>Completed the assigned tasks successfully</li>
          </ul>
        </div>
      </div> */}
      <ShareModal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        message={shareMessage}
      />
    </div>
  );
};

export default ReferralShare;
