import React from "react";
import PropTypes from "prop-types";
function NoDataFound(props) {
    return (
        <div style={{ ...wrapperStyle, ...props.style }}>
            <p style={{ fontSize: "40px", marginBottom: "8px" }}>{props.icon || "🌵"}</p>
            <p style={{ fontSize: "14px" }}>{props.message || "No data here."}</p>
        </div>
    );
}

export default NoDataFound;
const wrapperStyle = {
    padding: "10px 14px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    borderRadius: "5px",
};
NoDataFound.propTypes = {
    message: PropTypes.string,
    icon: PropTypes.any,
};
