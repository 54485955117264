import React from "react";
import PropTypes from "prop-types";
/**
 * @author Gulam Hussain
 * @param props
 * @return {*}
 * Show error message and functionality to retry
 */
function ErrorUI(props) {
    return (
        <div style={style}>
            <h2 style={{ margin: "4px" }}>
                <i className="fa fa-exclamation-triangle text-danger" />
            </h2>
            <p style={{ margin: "12px 0", textAlign: "center", color: "#666" }}>
                {props.message || "Something went wrong, Please try again. "}
            </p>
            <div onClick={props.retry} className="text-danger">
                <small style={{ cursor: "pointer" }}>
                    <strong>
                        <u>Click to try again</u>
                    </strong>
                </small>
            </div>
        </div>
    );
}

const style = {
    maxWidth: " 400px",
    margin: "20px auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "white",
    borderRadius: "5px",
    border: "1px solid #ccc",
    padding: "20px 10px",
    color: '#ae1717'
};

export default ErrorUI;
ErrorUI.propTypes = {
    message: PropTypes.string,
    retry: PropTypes.func.isRequired,
};
