import React from "react";
import Modal from "react-modal";
import "./ValidationErrorModal.css";

const ValidationErrorModal = ({ isOpen, onClose, errors }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="validation-modal"
      overlayClassName="validation-modal-overlay"
    >
      <div className="validation-modal-content">
        <h2 className="validation-modal-header">
          <span>Please Fix the Following Issues</span>
          <button className="validation-modal-close" onClick={onClose}>
            ×
          </button>
        </h2>
        <div className="validation-modal-body">
          {Object.entries(errors).map(([key, error]) => (
            <div key={key} className="validation-error-item">
              <div className="error-icon">!</div>
              <span>{error}</span>
            </div>
          ))}
        </div>
        <div className="validation-modal-footer">
          <button className="validation-modal-button" onClick={onClose}>
            Got it
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ValidationErrorModal;
