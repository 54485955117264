import React from "react";
import Modal from "react-modal";

const modalStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1000,
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  content: {
    position: "relative",
    top: "auto",
    left: "auto",
    right: "auto",
    bottom: "auto",
    border: "1px solid #ccc",
    background: "#fff",
    borderRadius: "8px",
    padding: "20px",
    maxWidth: "500px",
    width: "90%",
    maxHeight: "80vh",
    margin: "20px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    outline: "none",
  },
};

const modalContentStyle = {
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "24px",
  },
  title: {
    fontSize: "24px",
    textAlign: "center",
    fontWeight: "600",
    color: "#1f2937",
    margin: "0 0 10px 0",
    padding: "0",
    lineHeight: "1.2",
  },
  messageBox: {
    backgroundColor: "#f9fafb",
    padding: "16px",
    borderRadius: "8px",
    border: "1px solid #e5e7eb",
  },
  message: {
    color: "#374151",
    fontSize: "16px",
    lineHeight: "1.5",
    margin: 0,
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "10px",
  },
  button: {
    padding: "10px 24px",
    backgroundColor: "#2563eb",
    color: "white",
    border: "none",
    borderRadius: "6px",
    fontSize: "16px",
    fontWeight: "500",
    cursor: "pointer",
    transition: "background-color 0.2s ease",
    outline: "none",
  },
};

const PaymentUpdateModal = ({ isOpen, onClose, message }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={modalStyles}
      contentLabel="Payment Update Modal"
      ariaHideApp={false}
    >
      <div style={modalContentStyle.container}>
        <h2 style={modalContentStyle.title}>Payment Details Update</h2>
        <div style={modalContentStyle.messageBox}>
          <p style={modalContentStyle.message}>{message}</p>
        </div>
        <div style={modalContentStyle.buttonContainer}>
          <button
            onClick={onClose}
            style={modalContentStyle.button}
            onMouseOver={(e) => (e.target.style.backgroundColor = "#1d4ed8")}
            onMouseOut={(e) => (e.target.style.backgroundColor = "#2563eb")}
          >
            Continue
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default PaymentUpdateModal;
