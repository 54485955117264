import React, { useState, useEffect } from "react";
import ReactStars from "react-rating-stars-component";

const RatingSection = ({
  ratings,
  onRatingChange,
  t,
  ratingParameters,
  setRatingParameters,
}) => {
  const handleRatingChange = (newRating, criteriaKey, criteriaId) => {
    onRatingChange({
      ...ratings,
      [criteriaKey]: { value: newRating, criteriaId: criteriaId },
    });
  };

  return (
    <div style={styles.rating}>
      <h2 style={styles.heading}>{t("rating")}</h2>
      <div style={styles.ratingItems}>
        {ratingParameters.map((param) => (
          <div key={param.CriteriaID} style={styles.ratingItem}>
            <span style={styles.ratingLabel}>{t(param.CriteriaName)}:</span>
            <div style={styles.starContainer}>
              <ReactStars
                count={5}
                onChange={(newRating) =>
                  handleRatingChange(
                    newRating,
                    param.CriteriaKey,
                    param.CriteriaID
                  )
                }
                size={20}
                activeColor="#ffd700"
                value={ratings[param.CriteriaKey]?.value || 0}
              />
              {ratings[param.CriteriaKey]?.value > 0 && (
                <span style={styles.ratingValue}>
                  {ratings[param.CriteriaKey].value}
                </span>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const styles = {
  rating: {
    display: "flex",
    flexDirection: "column",
    gap: "0.15rem",
  },
  heading: {
    fontSize: "1.15rem",
    fontWeight: 600,
    marginBottom: "0.15rem",
  },
  ratingItems: {
    display: "flex",
    flexDirection: "column",
    gap: "0.15rem",
  },
  ratingItem: {
    display: "flex",
    alignItems: "flex-start",
    gap: "0.15rem",
  },
  ratingLabel: {
    minWidth: "150px",
    fontSize: "0.875rem",
    fontWeight: "bold",
    flexShrink: 0,
  },
  starContainer: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    gap: "0.15rem",
  },
  ratingValue: {
    fontSize: "0.875rem",
    fontWeight: "bold",
  },
};

export default RatingSection;
